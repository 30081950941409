import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {BaseResource} from '../base';
import {ApiResource} from '../api';
import {TextInterface} from '../../interfaces';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TextsResource {
  constructor(private http: HttpClient) { }

  public get(lang: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.translate.host + environment.translate.app + '/' + lang + '.json').subscribe(resolve, reject);
    });
  }
}
