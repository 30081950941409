import { Injectable } from '@angular/core';

import { BaseResource } from '../base';
import { ApiResource } from '../api';

@Injectable({
  providedIn: 'root',
})
export class LeadsResource extends BaseResource {
  constructor(public apiRsc: ApiResource) {
    super(apiRsc);
  }

  /**
   * Fetch countries from service
   */
  public async get(leadId: string): Promise<any> {
    return await this.apiRsc.get({ bapi: true, service: `lead/${leadId}` });
  }

  public async create(body: string): Promise<any> {
    return await this.apiRsc.post({ bapi: true, service: 'lead', body });
  }

  public getUploadInfo(leadId: string): Promise<any> {
    // TODO garantizar que sólo se da acceso al topic
    return this.apiRsc.get({
      bapi: true,
      service: `lead/${leadId}/bulk-address-url`,
    });
  }

  public async getContactOpts(): Promise<any> {
    return await this.apiRsc.get({ service: 'config/lead', bapi: true });
  }

  public updateAddressesFile(
    leadId: string,
    addressesFile: string
  ): Promise<any> {
    return this.apiRsc.patch({
      bapi: true,
      service: `lead/${leadId}/addresses-file`,
      body: { addressesFile },
    });
  }
}
