import { Component, OnInit, Injector, OnDestroy, Input } from '@angular/core';
import { RouterService } from '../../services';
import { BaseComponent } from '../base';
import { Subscription } from 'rxjs';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() public cookies = false;
  @Input() public sideNavOpt: string[] = [];
  public mobileLinks: any = {};
  public ups: any = [];
  public showprivateMenu = false;
  public showCountries = false;
  public countrySubscription: Subscription;
  public languages = this.langSrv.getMenuLangs();
  public country: string;
  public projectsOpen = false;

  constructor(
    public injector: Injector,
    public routerSrv: RouterService,
    private countrySrv: CountryService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.country = this.storageSrv.get('location') || null;
    this.countrySubscription = this.countrySrv
      .countryChange()
      .subscribe((country) => (this.country = country));
  }

  /**
   * opens private menu drop-down
   */
  public showHideprivateMenu(): void {
    this.showprivateMenu = !this.showprivateMenu;
  }

  /**
   * opens languages drop-down
   */
  public showCountriesLangs(): void {
    this.popoverSrv.close();

    this.popoverSrv.open('LocationLangComponent', 'wrapper-mobile-menu', {
      inputs: {
        topSpace: false,
      },
      outputs: {
        onClose: () => {
          this.popoverSrv.close('LocationLangComponent');
        },
      },
    });
  }

  /**
   * Logout click handler
   */
  public logout(): void {
    this.routerSrv.navigate('home');
    this.utilsSrv.closeMobileMenu();

    this.popoverSrv.close('PrivateMobileMenuComponent');
  }

  /**
   * Navigation from menu elemnt
   */
  public navigate(route: string): void {
    this.routerSrv.navigate(route);
    this.utilsSrv.closeMobileMenu();
  }

  /**
   * closes mobile menu after navigating
   */
  public mobileMenuNavigate(route: string): void {
    this.routerSrv.navigateToSpecificUrl(route);
    this.utilsSrv.closeMobileMenu();
  }

  /**
   * Close Mobile menu
   */
  public closeMobileMenu(): void {
    this.utilsSrv.closeMobileMenu();

    // Set a false inner menus
    this.showprivateMenu = false;
    this.showCountries = false;
  }

  public navToSection(section: string): void {
    this.closeMobileMenu();
    this.domSrv.scrollTo(`#${section}`);
  }

  ngOnDestroy(): void {
    if (this.countrySubscription) {
      this.countrySubscription.unsubscribe();
    }
  }
}
