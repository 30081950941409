import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CFDesignModule } from '@crowdfarming/cf-design';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { TranslationPipe } from '../../pipes/translation/translation.pipe';
import { TruncatePipe } from '../../pipes/truncate/truncate.pipe';
import { CDNPipe } from '../../pipes/cdn/cdn.pipe';
import { CFCurrencyPipe } from '../../pipes/currency/currency.pipe';

import { StopPropagationDirective } from '../../directives/stop-propagation/stop-propagation.directive';
import { MaskInputDirective } from '../../directives/mask/mask.directive';
import { DatacyDirective } from '../../directives/datacy/datacy.directive';
import { ImageLazyComponent } from '../../components/image-lazy/image-lazy.component';
import { CardComponent } from '../../components/card/card.component';
import { InfoListModule } from '../../components/info-list/info-list.module';

@NgModule({
  declarations: [
    TranslationPipe,
    TruncatePipe,
    CDNPipe,
    CFCurrencyPipe,
    StopPropagationDirective,
    MaskInputDirective,
    DatacyDirective,
    ImageLazyComponent,
    CardComponent,
  ],
  imports: [CommonModule, InfiniteScrollModule, InfoListModule],
  providers: [CDNPipe, TruncatePipe, TranslationPipe, CFCurrencyPipe],
  exports: [
    CFDesignModule,
    TranslationPipe,
    TruncatePipe,
    CDNPipe,
    CFCurrencyPipe,
    StopPropagationDirective,
    MaskInputDirective,
    DatacyDirective,
    ImageLazyComponent,
    CardComponent,
  ],
})
export class SharedModule {}
