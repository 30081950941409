import {Injector} from '@angular/core';
import {LangService, DomService, StorageService} from '../../services';
import {PopupService} from '../../services/popup';
import {PopoverService} from '../../services/popover';
import {environment} from '../../../environments/environment';
import {UtilsService} from 'src/app/services/utils/utils.service';
import {TextService} from 'src/app/services/text/text.service';
export abstract class BaseComponent {
  public textSrv: TextService;
  public env = environment;
  public langSrv: LangService;
  public utilsSrv: UtilsService;
  public domSrv: DomService;
  public popupSrv: PopupService;
  public popoverSrv: PopoverService;
  public storageSrv: StorageService;

  constructor(public injector: Injector) {
    this.textSrv = this.injector.get(TextService);
    this.langSrv = this.injector.get(LangService);
    this.utilsSrv = this.injector.get(UtilsService);
    this.domSrv = this.injector.get(DomService);
    this.popupSrv = this.injector.get(PopupService);
    this.popoverSrv = this.injector.get(PopoverService);
    this.storageSrv = this.injector.get(StorageService);
  }
}
