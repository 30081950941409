import {Injectable, Injector} from '@angular/core';

import {BaseService} from '../base';

@Injectable({
  providedIn: 'root'
})
export class FontsService extends BaseService {
  constructor(public injector: Injector) {
    super(injector);
  }

  /**
   * Init fonts service
   */
  public init(): void {
    setTimeout(() => {
      this.loadFonts();
    }, 0);
  }

  /**
   * Inset font scrips in index
   */
  private loadFonts(): void {
    if (typeof window !== 'undefined') {
      const gFont = document.createElement('link');

      gFont.href = 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap';
      gFont.rel = 'stylesheet';
      gFont.as = 'style';

      document.body.appendChild(gFont);
    }
  }
}
