import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  HostListener,
  Inject,
  PLATFORM_ID,
  InjectionToken,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { RouterService } from '../../services';
import { BaseComponent } from '../base';
import { Subscription } from 'rxjs';
import { CountryService } from '../../services/country/country.service';
import { TextService } from '../../services/text/text.service';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() public sideNavOpt: string[] = [];
  @Output() clickSideNav = new EventEmitter<string>();
  @Output() clickMobileMenu = new EventEmitter<boolean>();

  public isUpPage = true;
  public cartSubscription: Subscription;
  public countrySubscription: Subscription;
  public routeChangeSubscription: Subscription;
  public cartCounter = 0;
  public textSrv: TextService;
  public locationLangOpen = false;
  public canShare = false;
  public isNavHidden = false;

  public country: string;

  // Detect screen size to paint carousel
  public isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(e: any): void {
    this.isSmallScreen = e.target.innerWidth < 1024;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(_e: Event): void {}

  constructor(
    public injector: Injector,
    public routerSrv: RouterService,
    private countrySrv: CountryService,
    @Inject(PLATFORM_ID) private readonly platformId: InjectionToken<any>
  ) {
    super(injector);
    // eslint-disable-next-line
    // @ts-ignore
  }

  ngOnInit(): void {
    // Get device size
    this.isSmallScreen = this.domSrv.getIsDeviceSize('tablet') ? true : false;
    this.country = this.storageSrv.get('location') || null;
    this.countrySubscription = this.countrySrv
      .countryChange()
      .subscribe((country) => (this.country = country));
    this.routeChangeSubscription = this.routerSrv
      .onRouteChange()
      .subscribe((param: NavigationEnd) => {
        this.isNavHidden = param.url.includes('contact');
      });
  }

  ngOnDestroy(): void {
    this.countrySubscription?.unsubscribe();
    this.routeChangeSubscription?.unsubscribe();
  }

  public async sharePage(): Promise<void> {
    try {
      await navigator.share({
        title: this.textSrv.getText('metaTitle.home'),
        text: this.textSrv.getText('metaDescription.home'),
        url: `https://business.crowdfarming.com/${this.langSrv.getCurrentLang()}`,
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  /**
   * Location and language popup
   */
  public locationAndLanguage(): void {
    if (!this.locationLangOpen) {
      this.locationLangOpen = true;

      this.popoverSrv.open('LocationLangComponent', 'header-location', {
        inputs: {},
        outputs: {
          onClose: () => {
            this.popoverSrv.close('LocationLangComponent');
            this.locationLangOpen = false;
          },
        },
      });
    } else {
      this.popoverSrv.close('LocationLangComponent');
      this.locationLangOpen = false;
    }
  }

  public goToSection(section: string): void {
    this.clickSideNav.emit(section);
  }

  public openMenu(): void {
    this.clickMobileMenu.emit(true);
  }
}
