import { Injectable } from '@angular/core';
import { BaseResource } from '../base';
import { ApiResource } from '../api';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeResource extends BaseResource {
  constructor(public apiRsc: ApiResource) {
    super(apiRsc);
  }

  public async getCounters(): Promise<any> {
    return await this.apiRsc.get({ service: 'home/counters' });
  }

  public async getFarmers(): Promise<any> {
    return await this.apiRsc.get({
      service: `${environment.mainDomain}/config/home/farmers.json`,
      noApi: true,
    });
  }
}
