/**
 * File to define analytcis constants to be used across components and sergices
 */
export class TrackingConstants {
  // CONSTANTS FOR GTM
  public static GTM = {
    EVENTS: {
      CONTENT: 'content',
      LOGOUT: 'logout',
      LOGIN: 'login',
      REGISTER: 'register',
      NEWSLETTER: 'newsletter',
      OPEN_NEWSLETTER: 'Open subscription pop-up',
      ERROR: 'error',
      PURCHASE: 'purchase',
      REFUND: 'refund',
      CHECKOUT: 'checkout',
      CHECKOUT2: 'checkout 2',
      CHECKOUT3: 'checkout 3',
      CHECKOUT4: 'checkout 4',
      ADD_TO_CART: 'addToCart',
      IMPRESSION: 'impression',
      PRODUCT_CLICK: 'productClick',
      PRODUCT_DETAIL: 'productDetail',
      REMOVE_FROM_CART: 'removeFromCart',
      FILTER: 'filter',
      OOSZ: 'Out of shipment zone confirm message'
    },
    ACTIONS: {
      OVERHARVEST: 'overharvest',
      PURCHASE_MODULE: 'purchaseModule',
      PLAN_ALL: 'plandelivery-btn-addall',
      CROSSSELLING: 'csCart',
      PLAN_DELIVERY: 'Plan delivery',
      PLAN_DELIVERY_DETAILS: 'Plan delivery details',
      PLAN_DELIVERY_DETAILS_2: 'Plan delivery details2',
      RENEW: 'Renew',
      RENEW_DETAILS: 'Renew details',
      REORDER: 'Reorder',
      HOME: 'Home',
      FARMER_MARKET: 'Farmers Market'
    },
    PARAMS: {
      CROWDFARMER: 'crowdfarmer',
      AUTO: 'auto',
      MANUAL: 'manual',
      FB: 'facebook',
      GOOGLE: 'google',
      OVERHARVEST: 'OH',
      SB: 'SB',
      ADOPT: 'adopt',
      RENEW: 'renew'
    },
    PAGE_TYPE: {
      FARMER: 'farmer'
    }
  };
}
