import { Component, OnInit, HostListener } from '@angular/core';
import {
  StorageService,
  VideoService,
  LoggerService,
  RouterService,
  DomService,
  LangService,
} from './services';

import { environment } from '../environments/environment';
import { PopupService } from './services/popup';
import { UtilsService } from './services/utils/utils.service';
import { FontsService } from './services/fonts';
import { CookiesService } from './services/cookies/cookies.service';
import { CountryService } from './services/country/country.service';
import { TrackingService } from './services/tracking/tracking.service';
import { SeoService } from './services/seo/seo.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public countries: any[];
  public currentCountry: string;
  public isAvailableCountry: boolean;

  public availableCountries: any[];
  public allCountries: any[];

  public sideNavOpt = [];

  // Detect screen size to paint carousel
  public isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(e: Event): void {
    const target = e.target as Window;
    this.domSrv.setIsMobile(target.innerWidth);
    this.isSmallScreen = target.innerWidth < 1024;
  }

  constructor(
    private trackingSrv: TrackingService,
    private storageSrv: StorageService,
    private videoSrv: VideoService,
    public cookiesSrv: CookiesService,
    private logSrv: LoggerService,
    private fontSrv: FontsService,
    private countrySrv: CountryService,
    public domSrv: DomService,
    private utilsSrv: UtilsService,
    public routerSrv: RouterService,
    private seoSrv: SeoService,
    public popupSrv: PopupService,
    private langSrv: LangService
  ) {}

  public async ngOnInit(): Promise<any> {
    let country = null;
    if (this.domSrv.isPlatformBrowser()) {
      const parameters = new URLSearchParams(window.location.search);
      country = parameters.get('country') || null;
    }

    // Init logger service
    this.logSrv.init();
    // Load analytics scripts
    this.trackingSrv.init();
    // Load storage
    this.storageSrv.init();
    // Seo service
    this.seoSrv.init();
    // Get countries
    this.availableCountries = await this.countrySrv.init(country);
    this.allCountries = await this.countrySrv.get(true, true);
    // Load cookies
    this.cookiesSrv.init();
    // Load fonts
    this.fontSrv.init();
    // Init cart service
    // Video service
    this.videoSrv.init();

    // Set version of app
    this.storageSrv.set('appVersion', environment.VERSION);

    // Get device size
    this.isSmallScreen = this.domSrv.getIsDeviceSize('tablet') ? true : false;

    // Show market study popup
    this.showMarketStudyPopUp(this.availableCountries);

    // Setup navigation based on route
    await this.initSideNav();
  }

  private showMarketStudyPopUp(availableCountries: any[]): void {
    if (availableCountries) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.currentCountry = this.storageSrv.get('location');
      this.countries = availableCountries;
      this.isAvailableCountry =
        this.countries.filter(
          (country: any) => country.iso === this.currentCountry
        ).length > 0;

      // If current country is not one of the availables,
      // and if url is home, farmers market, project or overharvest, trigger popup after 1s
    }
  }

  private async initSideNav(): Promise<void> {
    await this.checkShowSideNav();
    this.routerSrv.onRouteChange().subscribe(async () => {
      await this.checkShowSideNav();
    });
  }

  private async checkShowSideNav(): Promise<void> {
    const showSideNav =
      this.routerSrv.getPath().includes('digital-garden') ||
      this.routerSrv.getPath().includes('huerto-digital');

    this.sideNavOpt = showSideNav
      ? [
          {
            name: await this.langSrv.translateAsync('page.environment.title'),
            id: 'impact',
          },
          {
            name: await this.langSrv.translateAsync('page.garden.title'),
            id: 'grove',
          },
          {
            name: await this.langSrv.translateAsync('page.social.title'),
            id: 'social',
          },
          {
            name: await this.langSrv.translateAsync('page.farmers.title'),
            id: 'farmers',
          },
        ]
      : [];
  }

  goToSection(section: string): void {
    this.domSrv.scrollTo(`#${section}`);
  }

  openMobileMenu(): void {
    this.utilsSrv.openMobileMenu();
  }
}
