<div class="popover-accordion cf-shadow-s"
  [ngStyle]="{'height' : scrollableHeight > 0 ? scrollableHeight + 'px' : 'auto', 'overflow-y': 'auto'}" id="accordion"
  [attr.data-cy]="'accordion-popover'">
  <div *ngFor="let section of sections; index as i" (click)="changeSection(i)" class="section t-black-T"
    [ngClass]="section === selected ? 't-l-bold' : 't-l-regular'" [attr.data-cy]="'accordion-popover-list-item-' + i">
    <div class="container" [attr.data-cy]="'accordion-popover-list-item-' + i + '-container'">
      <div *ngIf="section === selected" class="fa fa-angle-right"
        [attr.data-cy]="'accordion-popover-list-item-' + i + '-container-selected-arrow'">
      </div>
      {{textSrv.getText(section)}}
    </div>
  </div>
</div>
