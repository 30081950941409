<div id="location-selector-popover"
  [ngClass]="{'location-selector-popover-open': isOpen, 'location-selector-popover-space': topSpace}"
  class="location-selector-popover popover-container cf-shadow-m pa-l" [attr.data-cy]="'location-selector-popover'">
  <div *ngIf="domSrv.getIsDeviceSize()" class="d-flex align-items-center justify-content-end mb-l mt-s">
    <div class="hide-desktop ph-s" (click)="closeWithAnimation()"
      [attr.data-cy]="'location-selector-popover-close-button'">
      <i class="eva eva-close-outline t-h2-regular" aria-hidden="true" *ngIf="!country"
        [attr.data-cy]="'location-selector-popover-close-button-icon'"></i>
    </div>
  </div>

<!--  <div class="location-selector-popover-dropdown mb-m mt-xs"-->
<!--    [attr.data-cy]="'location-selector-popover-dropdown-country'">-->
<!--    <div class="t-s-medium mb-xs" [attr.data-cy]="'location-selector-popover-dropdown-country-title'">-->
<!--      {{textSrv.getText('Deliver to')}}-->
<!--    </div>-->

<!--    <cf-dropdown [width]="'auto'" [containerShadow]="false" (isOpenChange)="locationOpen = $event"-->
<!--      [datacy]="'location-selector-popover-dropdown-country-'"-->
<!--      [attr.data-cy]="'location-selector-popover-dropdown-country-cf-dropdown'">-->
<!--      <cf-selector dropdown-selected class="cf-dropdown-selected nop"-->
<!--        [title]="newCountry || currentIso ? (countriesByIso[newCountry || currentIso] | translation: 'name') : textSrv.getText('country')"-->
<!--        [value]="currentIso !== undefined || currentIso !== null || newCountry" [size]="'m'"-->
<!--        [icon]="currentIso || newCountry ? '' : 'globe-2-outline'"-->
<!--        [flag]="newCountry || currentIso ? newCountry || currentIso : ''" [isOpen]="locationOpen"-->
<!--        [datacy]="'location-selector-popover-dropdown-country-cf-dropdown-ng-content-dropdown-selected-'"-->
<!--        [attr.data-cy]="'location-selector-popover-dropdown-country-cf-dropdown-ng-content-dropdown-selected-cf-selector'">-->
<!--      </cf-selector>-->

<!--      <div dropdown-item *ngFor="let country of countries; let i = index" class="cf-dropdown-item"-->
<!--        (click)="newCountry = country.iso"-->
<!--        [attr.data-cy]="'location-selector-popover-dropdown-country-cf-dropdown-ng-content-dropdown-item-list-item-' + i">-->
<!--        <div class="cf-dropdown-content flex-center"-->
<!--          [attr.data-cy]="'location-selector-popover-dropdown-country-cf-dropdown-ng-content-dropdown-item-list-item-' + i + '-name'">-->
<!--          <div class="flag-icon flag-icon-{{country.iso}}"-->
<!--            [attr.data-cy]="'location-selector-popover-dropdown-country-cf-dropdown-ng-content-dropdown-item-list-item-' + i + '-icon'">-->
<!--          </div>-->
<!--          {{country | translation: 'name'}}-->
<!--        </div>-->
<!--      </div>-->
<!--    </cf-dropdown>-->
<!--  </div>-->

  <div class="location-selector-popover-dropdown" [attr.data-cy]="'location-selector-popover-dropdown-language'">
    <div class="t-s-medium mb-xs" [attr.data-cy]="'location-selector-popover-dropdown-language-title'">
      {{textSrv.getText('Language')}}
    </div>

    <cf-dropdown [width]="'auto'" [containerShadow]="false" (isOpenChange)="langOpen = $event"
      [datacy]="'location-selector-popover-dropdown-language-'"
      [attr.data-cy]="'location-selector-popover-dropdown-language-cf-dropdown'">
      <cf-selector dropdown-selected class="cf-dropdown-selected nop"
        [title]="newLang ? newLang.toUpperCase() : langSrv.getCurrentLang().toUpperCase()" [size]="'m'" [icon]="''"
        [value]="true" [isOpen]="langOpen"
        [datacy]="'location-selector-popover-dropdown-language-cf-dropdown-ng-content-dropdown-selected-'"
        [attr.data-cy]="'location-selector-popover-dropdown-language-cf-dropdown-ng-content-dropdown-selected-cf-selector'">
      </cf-selector>

      <div dropdown-item *ngFor="let lang of languages; let i = index" class="cf-dropdown-item w-100"
        (click)="newLang = lang.shortName"
        [ngClass]="{'cf-dropdown-current': lang.shortName === langSrv.getCurrentLang().toUpperCase()}"
        [attr.data-cy]="'location-selector-popover-dropdown-language-cf-dropdown-ng-content-dropdown-item-list-item-' + i">
        <div class="cf-dropdown-content"
          [attr.data-cy]="'location-selector-popover-dropdown-language-cf-dropdown-ng-content-dropdown-item-list-item-' + i + '-name'">
          {{lang.longName}}
        </div>
      </div>
    </cf-dropdown>
  </div>

  <div class="w-100 mt-m" [attr.data-cy]="'location-selector-accept-button'">
    <cf-button [width]="'auto'" (click)="accept()" [datacy]="'location-selector-accept-button-'"
      [attr.data-cy]="'location-selector-accept-button-cf-button'">
      {{textSrv.getText('Accept')}}
    </cf-button>
  </div>
</div>
