import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {BaseResource} from '../base';
import {ApiResource} from '../api';
import {StorageService} from '../../services/storage';
import {LangService} from '../../services/lang';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorResource extends BaseResource implements HttpInterceptor {
  constructor(public apiRsc: ApiResource, private storageSrv: StorageService, private langSrv: LangService) {
    super(apiRsc);
  }

  /**
   * Req Interceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isWP = request.params.get('wp') || request.url.includes('loggly');

    const token: string = this.storageSrv.get('capi-token');
    const sessionId = this.storageSrv.get('sessionId', 2);
    const currentCountry = this.storageSrv.get('location') || null;

    if (!isWP) {
      request = request.clone({
        headers: request.headers
          .set('Accept-Language', this.langSrv.getCurrentLang())
          .set('X-Version', environment.VERSION)
          .set('X-Origin-CF', environment.mainDomain)
          .set('X-Country', currentCountry || 'Not detected')
      });

      if (sessionId) {
        request = request.clone({headers: request.headers.set('X-SessionId', sessionId)});
      }

      if (!(request.url.includes('tickets/add-photos') || request.url.includes('contacts/add-photos'))) {
        request = request.clone({headers: request.headers.set('Accept', 'application/json').set('Content-Type', 'application/json')});
      }

      if (token) {
        request = request.clone({headers: request.headers.set('Authorization', token)});
      }
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.checkAuth(event);
        }

        return event;
      }),
      (error: any) =>
        // TODO: Handle errors
        error
    );
  }

  /**
   * Check auth requests
   */
  private checkAuth(event): void {
    if (event && event.url && event.url.includes('/login')) {
      // Login request
      if (event.body && event.body.data && event.body.data.token) {
        // Store token for requests
        this.storageSrv.set('capi-token', event.body.data.token);
        this.storageSrv.set('logged', true, 2592000);
      }
    } else if (event.headers.has('X-Auth-Token')) {
      // Regular request update token
      this.storageSrv.set('capi-token', event.headers.get('X-Auth-Token'));
      this.storageSrv.set('logged', true, 2592000);
    }
  }
}
