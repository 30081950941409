<ng-container *transloco="let t">
  <div
    class="cookies-container row-no-margin justify-content-center"
    id="cookies-component-wraper"
    [attr.data-cy]="'cookies-component-wrapper'"
  >
    <div
      class="cookies-text-container col-12 col-md-8 flex-center f14"
      [attr.data-cy]="'cookies-component-wrapper-info'"
    >
      {{ t('cookies.title') }}
    </div>
    <div
      class="cookies-buttons-container col-12 col-md-4 col-lg-2 flex-center"
      [attr.data-cy]="'cookies-component-wrapper-buttons'"
    >
      <div
        class="row-no-margin justify-content-center"
        [attr.data-cy]="'cookies-component-wrapper-buttons-container'"
      >
        <div
          class="cookies-cf-button-container col-12 col-no-padding mb-s"
          [attr.data-cy]="
            'cookies-component-wrapper-buttons-container-accept-button'
          "
        >
          <cf-button
            (click)="accept()"
            [black]="true"
            [width]="'auto'"
            [size]="'s'"
            [datacy]="
              'cookies-component-wrapper-buttons-container-accept-button-'
            "
            [attr.data-cy]="
              'cookies-component-wrapper-buttons-container-accept-button-cf-button'
            "
          >
            {{ t('cookies.ok') }}
          </cf-button>
        </div>
        <div
          class="cookies-manage-page text-link text-link-white"
          (click)="goToCookiesPage()"
          [attr.data-cy]="
            'cookies-component-wrapper-buttons-container-manage-button'
          "
        >
          {{ t('cookies.manage') }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
