import {Component, OnInit, Injector} from '@angular/core';

import {BaseComponent} from '../base';

import {RouterService} from '../../services/router';
import {CookiesService} from 'src/app/services/cookies/cookies.service';

@Component({
  selector: 'cookies-component',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent extends BaseComponent implements OnInit {
  constructor(public injector: Injector, private routerSrv: RouterService, private cookiesSrv: CookiesService) {
    super(injector);
  }

  ngOnInit(): void { }

  /**
   * Accept cookies
   */
  public accept(): void {
    this.cookiesSrv.accept();
  }

  /**
   * Transition to cookies management
   */
  public goToCookiesPage(): void {
    this.routerSrv.navigate('cookies-management');
  }
}
