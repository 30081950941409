import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverBaseComponent } from './base/base.component';
import { AccordionPopoverComponent } from './accordion-popover';
import { LocationLangComponent } from './location-lang';
import { UpCardMobileComponent } from './up-card-mobile';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../modules/shared/shared.module';
import { Leadf2bPopoverComponent } from './lead-f2b-popover';

@NgModule({
    declarations: [
        PopoverBaseComponent,
        LocationLangComponent,
        Leadf2bPopoverComponent,
        AccordionPopoverComponent,
        UpCardMobileComponent,
    ],
    imports: [CommonModule, SharedModule, FormsModule]
})
export class PopoverModule {}
