import {Injectable, Injector} from '@angular/core';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: any;
  private version: number;
  private versionClean: number;

  constructor(public injector: Injector) { }

  /**
   * Init service
   */
  public init(): any {
    // Load storage version
    this.version = environment.storage.version;
    this.versionClean = environment.storage.versionClean;

    this.selectStorage();
  }

  /**
   * Set key and value in storage
   *
   * @param time miliseconds
   */
  public set(key: string, value: any, time: number = 0, versionLess: boolean = false, type: number = 1): void {
    if (!this.storage) {
      this.init();
    }

    const params = {
      data: value,
      time: time > 0 ? new Date().getTime() + time * 1000 : time,
      version: versionLess ? 0 : this.version
    };

    if (type === 1) {
      this.storage.setItem(key, JSON.stringify(params));
    } else if (type === 2 && this.checkSessionStorage()) {
      sessionStorage.setItem(key, JSON.stringify(params));
    }
  }

  /**
   * Get valeu from key
   */
  public get(key: string, type: number = 1): any {
    if (!this.storage) {
      this.init();
    }

    let storedDagta;

    if (type === 1) {
      storedDagta = this.storage.getItem(key);
    } else if (type === 2 && this.checkSessionStorage()) {
      storedDagta = sessionStorage.getItem(key);
    }

    try {
      const parsedData = JSON.parse(storedDagta);
      const time = new Date().getTime();

      if (parsedData && (parsedData.time || parsedData.time === 0) && parsedData.version) {
        if (parsedData.time === 0 || (parsedData.time >= time && (parsedData.version === 0 || parsedData.version === this.version))) {
          return parsedData.data;
        }
      }

      return null;
    } catch (e) {
      return null;
    }
  }

  public clearPrefix(prefix: string): void {
    if (prefix) {
      if (!this.storage) {
        this.init();
      }

      Object.entries(this.storage)
        .map(x => x[0])
        .filter(x => x.startsWith(prefix))
        .map(x => this.storage.removeItem(x));
    }
  }

  /**
   * Clear all storage or just one key
   */
  public clear(key?: string, type: number = 1): void {
    if (!this.storage) {
      this.init();
    }

    if (type === 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      key ? this.storage.removeItem(key) : this.storage.clear();
    } else if (type === 2) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      key ? sessionStorage.removeItem(key) : sessionStorage.clear();
    }
  }

  /**
   * Auto clean storage
   */
  private autoClean(): void {
    if (!this.storage) {
      this.init();
    }

    const cleanCache = this.storage.getItem('cleanCache');

    if (cleanCache === null || cleanCache !== this.versionClean) {
      for (const key in this.storage) {
        if (/^cache.*/g.test(key)) {
          this.storage.removeItem(key);
        }
      }

      this.storage.setItem('cleanCache', this.versionClean);
    }
  }

  private checkSessionStorage() {
    const testKey = 'testNameStorage';

    try {
      sessionStorage.setItem(testKey, '1');
      sessionStorage.removeItem(testKey);

      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Select a valid storage
   */
  private selectStorage(): any {
    const testKey = 'testNameStorage';

    try {
      localStorage.setItem(testKey, '1');
      localStorage.removeItem(testKey);

      this.storage = localStorage;
      this.autoClean();
    } catch (error) {
      try {
        sessionStorage.setItem(testKey, '1');
        sessionStorage.removeItem(testKey);

        this.storage = sessionStorage;
        this.autoClean();
      } catch (error2) {
        // Fake sessionStorage in cookies for safari
        this.storage = {
          setItem: (name: string, value) => {
            if (typeof document !== 'undefined') {
              let b64Data = '';

              try {
                b64Data = btoa(value);
              } catch (error3) {
                return;
              }

              if (b64Data.length <= 4000) {
                document.cookie = name + '=' + b64Data + '; path=/; SameSite=Lax';
              }
            }
          },
          getItem: (name: string) => {
            if (typeof document !== 'undefined') {
              const nameEQ = name + '=';
              const ca = document.cookie.split(';');

              for (let c of ca) {
                while (c.charAt(0) === ' ') {
                  c = c.substring(1, c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                  return atob(c.substring(nameEQ.length, c.length));
                }
              }
            }

            return null;
          },
          removeItem: (_key) => { }
        };
      }
    }
  }
}
