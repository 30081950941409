import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { BaseService } from '../base';
import { LangService } from '../lang';
import { StorageService } from '../storage';
import { EventService } from '../event';
import { Location } from '@angular/common';
import { DomService } from '../dom';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterService extends BaseService {
  static originalURL: { search: any; hash: string; href: string } = {
    search: {},
    hash: '',
    href: '',
  };

  // Mandatory implementation
  public init: any = () => null;

  constructor(
    private router: Router,
    private langSrv: LangService,
    public injector: Injector,
    public eventSrv: EventService,
    private location: Location,
    public storageSrv: StorageService,
    public domSrv: DomService
  ) {
    super(injector);

    if (domSrv.isPlatformBrowser()) {
      RouterService.originalURL.href = window.location.href;
      RouterService.originalURL.hash = window.location.hash;
      for (const pair of window.location.search.split('?').pop().split('&')) {
        const members = pair.split('=').map((member) => member.trim());
        RouterService.originalURL.search[members[0].toLowerCase()] = members[1];
      }
    }
  }

  /**
   * Navigate to route
   */
  public navigate(
    route: string,
    lang?: string,
    query?: any,
    data?: string,
    keepScrollStorage?: boolean
  ): void {
    // Init inner loader
    this.eventSrv.dispatchEvent('loading-animation', { detail: { set: true } });

    // Handle data insertion
    const params = data
      ? [
          '/' + (lang ? lang : this.langSrv.getCurrentLang()) + '/' + route,
          data,
        ]
      : ['/' + (lang ? lang : this.langSrv.getCurrentLang()) + '/' + route];

    if (!keepScrollStorage) {
      this.storageSrv.clear('projectsScrollTo');
    }

    // handle query params
    // fragments now comes as queryParam
    const extra = {
      queryParams: null,
    };

    if (query) {
      extra.queryParams = query;
    }

    // Check if url has already queries on it
    // In example mails with utms
    let url: string;
    if (/\?/.test(params[0])) {
      const par = params[0].split('?');
      extra.queryParams = JSON.parse(
        '{"' + par[1].replace(/&/g, '","').replace(/=/g, '":"') + '"}'
      );
      url = par[0];
    }

    // Navigate
    if (url) {
      void this.router.navigate([url], extra);
    } else {
      void this.router.navigate(params, extra);
    }

    // Hide inner loader
    this.eventSrv.dispatchEvent('loading-animation', {
      detail: { set: false },
    });
  }

  /**
   * middle function to redirect to multilingual urls not managed by slugs
   */

  public navigateToSpecificUrl(url: string): void {
    let routesStack = {};

    const contact = {
      es: 'contacto',
      en: 'contact',
      de: 'kontakt',
      fr: 'contact',
    };

    // insert new multilingual urls stack
    switch (url) {
      case 'contact':
        routesStack = contact;
        break;
    }

    this.navigate(
      routesStack[this.langSrv.getCurrentLang()],
      null,
      null,
      null,
      true
    );
  }

  /**
   * Get path of current url
   */
  public getPath(getQueryParams = false): string {
    return getQueryParams
      ? this.router.url.split('?')[1]
      : this.router.url.split('?')[0];
  }

  /**
   * Gets if it's home
   */

  public getIsHome(): any {
    return this.getPath().length <= 3;
  }

  /**
   * Removes url lang to translate it in navigate function
   */
  public removeUrlLang(): string {
    const url = this.getPath();
    if (url.length > 3) {
      const regExp = /\/[a-z]{2}\//;
      return url.replace(regExp, '');
    } else {
      return '/';
    }
  }
  /**
   * navigates back to previous page
   */
  public goBack(): void {
    this.location.back();
  }

  public onRouteChange(): Observable<Event> {
    return this.router.events.pipe(
      filter((ev: Event) => ev instanceof NavigationEnd)
    );
  }
}
