import {HostListener, Injectable, Injector} from '@angular/core';
import {LogglyService} from 'ngx-loggly-logger';
import {BaseService} from '../base';

import {environment} from '../../../environments/environment';
import {StorageService} from '../storage';
import {DeviceDetectorService} from 'ngx-device-detector';
@Injectable({
  providedIn: 'root'
})
export class LoggerService extends BaseService {
  @HostListener('window:WindowOnError')
  onWindowOnError(event: any): void {
    this.error(event?.detail?.message);
  }

  active = false;

  constructor(
    public injector: Injector,
    private logglyService: LogglyService,
    private storageSrv: StorageService,
    private deviceService: DeviceDetectorService
  ) {
    super(injector);
    this.active = environment.loggly.active && typeof window === 'object';
  }

  /**
   * Init logger sercive
   */
  public init(): void {
    this.createSessionId();

    if (this.active) {
      try {
        this.logglyService.push({
          logglyKey: environment.loggly.logglyKey,
          sendConsoleErrors: environment.loggly.sendConsoleErrors,
          json: true,
          tag: environment.loggly.tag,
          useDomainProxy: false
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  public createSessionId(force: boolean = false): void {
    if ((!this.storageSrv.get('sessionId', 2) || force) && typeof window === 'object') {
      const deviceInfo = this.deviceService.getDeviceInfo();
      const user = this.storageSrv.get('userData');

      const sessionId = btoa(new Date().getTime() + '_$_' + deviceInfo.os + '_$_' +
        deviceInfo.browser + '_$_' + deviceInfo.browser_version + '_$_' + (user ? user._id : null));

      this.storageSrv.set('sessionId', sessionId, 0, null, 2);
    }
  }

  public fecthParams(): any {
    const deviceInfo = this.deviceService.getDeviceInfo();
    const user = this.storageSrv.get('userData');
    const currentCountry = this.storageSrv.get('location') || null;
    const sessionId = this.storageSrv.get('sessionId', 2);

    return {
      'x-browser': deviceInfo.browser + ' ' + deviceInfo.browser_version,
      'x-device': this.deviceService.isMobile() ? 'mobile' : this.deviceService.isTablet() ? 'tablet' : 'desktop',
      'x-userId': user ? user._id : null,
      'x-sessionId': sessionId || null,
      'x-country': currentCountry || 'Not detected',
      'x-os': deviceInfo.os
    };
  }

  public log(message: string): void {
    if (message) {
      console.log(message);

      if (this.active) {
        this.logglyService.push({level: 'info', message, timestamp: new Date().getTime(), ...this.fecthParams()});
      }
    }
  }

  public error(message: string): void {
    if (message) {
      if (this.active) {
        this.logglyService.push({level: 'error', message, timestamp: new Date().getTime(), ...this.fecthParams()});
      }
    }
  }

  public warning(message: string): void {
    if (message) {
      console.log(message);

      if (this.active) {
        this.logglyService.push({level: 'warning', message, timestamp: new Date().getTime(), ...this.fecthParams()});
      }
    }
  }
}
