/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoContentPageComponent } from './pages/no-content';
import { LangService } from './services';
import { CookiesService } from './services/cookies/cookies.service';
import { TextService } from './services/text/text.service';

const routes: Routes = [
  {
    path: ':lang',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/bundle-detail/bundle-detail.module').then(
            (m) => m.BundleDetailModule
          ),
      },
      {
        path: 'contact/:id',
        loadChildren: () =>
          import('./pages/bundle-contact/bundle-contact.module').then(
            (m) => m.BundleContactModule
          ),
      },
      {
        path: 'digital-garden/:company',
        loadChildren: () =>
          import('./pages/f2b/f2b.module').then((m) => m.F2bModule),
      },
      {
        path: 'huerto-digital/:company',
        loadChildren: () =>
          import('./pages/f2b/f2b.module').then((m) => m.F2bModule),
      },
      {
        path: 'directions/:leadId',
        loadChildren: () =>
          import('./pages/directions/directions.module').then(
            (m) => m.DirectionsModule
          ),
      },
      { path: 'home', pathMatch: 'full', redirectTo: '' },
      {
        path: 'cookies-management',
        loadChildren: () =>
          import('./pages/cookies-management/cookies-management.module').then(
            (m) => m.CookiesManagementModule
          ),
      },
    ],
    canActivate: [LangService],
    canActivateChild: [CookiesService],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/no-content/no-content.module').then(
        (m) => m.NoContentModule
      ),
    canActivate: [LangService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
