/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable, Injector } from '@angular/core';

import { BaseService } from '../base';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage';
import { TrackingConstants } from './tracking.constants';

import { getLCP, getFID, getCLS, getFCP, getTTFB } from 'web-vitals/base';
import { CountryService } from '../country/country.service';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class TrackingService extends BaseService {
  private enabled: any = {};
  private isTAPIEnabled: any = {};
  private enable = false;
  public interimlList: string;
  private tapi: any = null;

  constructor(
    public injector: Injector,
    private storageSrv: StorageService,
    private countrySrv: CountryService
  ) {
    super(injector);
  }

  /**
   * Init analytics service
   */
  public init(): void {
    this.enable =
      (environment.analytics.active && typeof window === 'object') || false;
    this.isTAPIEnabled =
      (environment.tapi.active && typeof window === 'object') || false;
    if (this.enable) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const me = this;
      setTimeout(() => {
        this.loadGTMScript(me);
        this.initCoreWebVitalsReporting();
      }, 0);
    }
  }

  public initCoreWebVitalsReporting(): void {
    getCLS(this.reportCoreWebVital);
    getFID(this.reportCoreWebVital);
    getLCP(this.reportCoreWebVital);
    getFCP(this.reportCoreWebVital);
    getTTFB(this.reportCoreWebVital);
  }

  public reportCoreWebVital(vital: any): void {
    const cwv = {
      event: 'coreWebVitals',
      webVitalsMeasurement: {
        id: vital.id,
        name: vital.name,
        valueRounded: Math.round(
          vital.name === 'CLS' ? vital.value * 1000 : vital.value
        ),
      },
    };
    window.dataLayer.push(cwv);
  }

  /**
   * Get if service is enabled
   */
  public getIsEnabled(service: string): boolean {
    return this.enabled[service] || false;
  }

  /**
   * Load hotjar script
   */
  public loadHotjar(): void {
    if (
      typeof window !== 'undefined' &&
      environment.hotjar &&
      environment.hotjar.active &&
      this.storageSrv.get('cookies').marketingAllowed &&
      !this.enabled.HJ
    ) {
      ((c, f, e: string, v: string) => {
        c.hj =
          c.hj ||
          (() => {
            // eslint-disable-next-line prefer-rest-params
            (c.hj.q = c.hj.q || []).push(arguments);
          });
        c._hjSettings = {
          hjid: environment.hotjar.id,
          hjsv: environment.hotjar.sv,
        };
        const n: any = f.getElementsByTagName('head')[0];
        const t: any = f.createElement('script');
        t.async = 1;
        t.src = e + String(c._hjSettings.hjid) + v + String(c._hjSettings.hjsv);
        n.appendChild(t);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

      this.enabled.HJ = true;
    }
  }

  /**
   * Load GTM script
   */
  private loadGTMScript(me): void {
    if (typeof window !== 'undefined' && me.enable && !me.enabled.GTM) {
      if (!window.dataLayer && !window.GTM) {
        ((w, d, s, l, i) => {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });

          const f = d.getElementsByTagName(s)[0];
          const j: any = d.createElement(s);
          const dl = l !== 'dataLayer' ? '&l=' + l : '';

          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', environment.analytics.gtm);

        // Create block for users whitous javascript active
        const gtagNoscript = document.createElement('noscript');
        const gtagIframe: any = document.createElement('iframe');

        gtagIframe.src =
          'https://www.googletagmanager.com/ns.html?id=' +
          environment.analytics.gtm;
        gtagIframe.width = '0';
        gtagIframe.height = '0';
        gtagIframe.style = 'display:none;visibility:hidden';

        gtagNoscript.appendChild(gtagIframe);
        document.body.appendChild(gtagNoscript);

        window.GTM = environment.analytics.gtm;
        window.dataLayer = window.dataLayer || [];

        me.enabled.GTM = true;
      }
    }
  }

  /**
   * Overwrite agroupation
   */
  public addGroup(pageType: string, farmer: string): void {
    if (this.enable) {
      window.dataLayer.push({
        event: TrackingConstants.GTM.EVENTS.CONTENT,
        pageType,
        farmer,
      });
    }
  }

  /**
   * Track event using GTM and datalayer
   */
  public trackEvent(
    event: string,
    ecommerce: boolean = false,
    data?: any,
    action?: string,
    extraEcommerce?: any
  ): void {
    if (this.enable) {
      // Init default params
      let params: any = {
        event,
      };

      // If data needs to be placed out of ecommerce object
      if (extraEcommerce) {
        params = {
          event,
          ...extraEcommerce,
        };
      }

      // If ecommerce add default currency and data
      if (ecommerce) {
        const country = this.countrySrv.getCurrentCountry();

        params.ecommerce = {
          currencyCode: country && country.currency ? country.currency : 'EUR',
          ...data,
        };
      } else if (data) {
        params = {
          event,
          ...data,
        };
      }

      // Add action if provided
      if (action) {
        params.action = action;
      }
      // Add params to dataLayer to track it
      window.dataLayer.push(params);
    }

    try {
      if (this.tapi) {
        void this.asyncTrackEvent(event, data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  asyncTrackEvent(event: any, data: any = {}): void {
    const userInfo = {};
    if (data.add) {
      for (const vars of data.add.products) {
        this.tapi.sendEvent('AddToCart', { ...vars, ...userInfo }).sendPixel();
      }
    }

    if (data.detail) {
      for (const vars of data.detail.products) {
        this.tapi
          .sendEvent('ViewContent', { ...vars, ...userInfo })
          .sendPixel();
      }
    }
    if (data.purchase) {
      this.tapi
        .sendEvent('Purchase', {
          stripe: data.purchase.actionField.id,
          content_ids: data.purchase.products.map(
            (product) => product.id + (product.variant === 'OH' ? '-oh' : '-ad')
          ),
          revenue: data.purchase.actionField.revenue,
          products: data.purchase.products,
          ...userInfo,
        })
        .sendPixel();
    }
    if (event === TrackingConstants.GTM.EVENTS.REGISTER) {
      this.tapi
        .sendEvent('CompleteRegistration', { ...data, ...userInfo })
        .sendPixel();
    }
    if (event === TrackingConstants.GTM.EVENTS.LOGIN) {
      this.tapi.sendEvent('Login', { ...data, ...userInfo }).sendPixel();
    }
  }

  setInterimList(name: string): void {
    this.interimlList = name;
  }

  getInterimList(): string {
    return this.interimlList;
  }
}
