import { Injectable, Injector } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { BaseService } from '../base';
import { CountriesResource } from '../../resources';
import { registerLocaleData } from '@angular/common';
import { TransferStateService } from '../../services';
import { CountryInterface } from '../../interfaces';
import { StorageService } from '../storage';
import { LangService } from '../lang';
import { ActivatedRoute } from '@angular/router';

type cacheType = { time: number; data: any };
type cacheCountryType = { get: cacheType; getAll: cacheType };

const countryCache: cacheCountryType = {
  get: { time: 0, data: {} },
  getAll: { time: 0, data: {} },
};

@Injectable({
  providedIn: 'root',
})
export class CountryService extends BaseService {
  private country = new Subject<string>();
  private loaded = {
    get: new Subject<boolean>(),
    getAll: new Subject<boolean>(),
  };
  private countries;

  constructor(
    private countriesRsc: CountriesResource,
    private storageSrv: StorageService,
    private langSrv: LangService,
    private stateSrv: TransferStateService,
    private route: ActivatedRoute,
    public injector: Injector
  ) {
    super(injector);
    this.loaded.get.next(false);
    this.loaded.getAll.next(false);
  }

  // Mandatory implementation
  public async init(initial?: string | null): Promise<any> {
    // TODO >> better initial country
    const countries = await this.get(true);

    this.setCountry(initial || this.getCountry(true));

    return countries;
  }

  /**
   * Get countries
   */
  public async get(force?: boolean, all = false): Promise<CountryInterface[]> {
    let countries;

    const funcCall = all ? 'getAll' : 'get';
    const stateKey = `countries_${funcCall}`;

    if (
      !force &&
      countryCache[funcCall] &&
      countryCache[funcCall].time &&
      countryCache[funcCall].time >= new Date().getTime()
    ) {
      countries = countryCache[funcCall].data;
    } else {
      countries = this.stateSrv.get(stateKey);
      if (countries === null) {
        countries = await this.countriesRsc[funcCall]();
        this.stateSrv.set(stateKey, countries);
      }
      // Store it
      countryCache[funcCall] = {
        data: countries,
        time: new Date().getTime() + 25000 * 1000,
      };
      this.loaded[funcCall].next(true);
    }

    this.countries = this.modelize(
      countries,
      ['_id', 'updatedAt', 'active'],
      true
    );

    return this.countries;
  }

  /**
   * Return counter observable
   */
  public getLoaded(all: boolean): Observable<boolean> {
    const funcCall = all ? 'getAll' : 'get';
    return this.loaded[funcCall].asObservable();
  }

  /**
   * Return country observable
   */
  public countryChange(): Observable<string> {
    return this.country.asObservable();
  }

  /**
   * getCountriesByISO
   */
  public async getCountriesByISO(): Promise<any> {
    const countries = await this.get(false, true);
    const countriesByISO = {};

    countries.map((country: any) => {
      countriesByISO[country.iso] = country;
    });

    return countriesByISO;
  }

  /**
   * getCountriesByISO
   */
  public async getCountriesByISOFilter(countriesWanted: any): Promise<any> {
    const countries = await this.get(false, true);
    const countriesByISO = {};

    countriesWanted.map((iso: string) => {
      const countryObject = countries.find((country) => country.iso === iso);
      if (countryObject.deliver) {
        countriesByISO[iso] = countryObject;
      }
    });

    return countriesByISO;
  }

  /**
   * Stores country
   *
   * @param country ISO
   */
  public setCountry(country: string): void {
    if (country !== null) {
      this.storageSrv.set('location', country);
      this.storageSrv.clear('filters');

      const fullCountry: { locale: string } = this.getCurrentCountry();
      if (fullCountry !== undefined) {
        void import(
          `/node_modules/@angular/common/locales/${fullCountry.locale}.js`
        ).then(
          (module) => {
            registerLocaleData(module.default);
          },
          () => {
            import(
              `node_modules/@angular/common/locales/${
                fullCountry.locale.split('-')[0]
              }.js`
            ).then(
              (module) => {
                registerLocaleData(module.default);
              },
              (error) => {
                // Just catch
                console.log(error);
              }
            );
          }
        );

        this.country.next(country);
      }
    }
  }

  /**
   * Returns country ISO
   *
   */
  public getCountry(init = false): string {
    let country =
      this.storageSrv.get('location') ||
      this.route.snapshot.queryParamMap.get('country') ||
      null;
    if (!country && !init) {
      const userData = this.storageSrv.get('userData');
      if (userData) {
        for (const address of userData.addresses) {
          if (address.country && address.favourite) {
            country = address.country;
            break;
          }
        }

        if (!location && userData.addresses[0]) {
          country = userData.addresses[0].country;
        }
      }

      if (!country) {
        switch (this.langSrv.getCurrentLang()) {
          case 'es':
            country = 'es';
            break;
          case 'en':
            country = 'gb';
            break;
          case 'fr':
            country = 'fr';
            break;
          case 'de':
            country = 'de';
            break;
          default:
            // always show something
            country = 'es';
            break;
        }
      }

      // this.storageSrv.set('location', country);
    }
    return country;
  }

  /**
   * check if country existes
   */
  public async checksCountryIso(
    iso: string,
    countries?: CountryInterface[]
  ): Promise<string> {
    if (!countries || countries.length === 0) {
      countries = await this.get();
    }

    for (const country of countries) {
      if (country.iso === iso) {
        return country.iso;
      }
    }

    return null;
  }

  /**
   * Filter country by iso
   *
   * @param iso country iso
   */
  public async getCountryByIso(iso?: string, countries?: any[]): Promise<any> {
    const list =
      countries && countries.length > 0 ? countries : await this.get();
    const actual = iso ? iso : this.getCountry();

    return list.filter((country) => country.iso === actual)[0];
  }

  getCurrentCountry(): any {
    if (!this.countries) {
      return { locale: 'es-ES', currency: 'EUR' };
    }
    return this.countries.filter((c) => c.iso === this.getCountry())[0];
  }
}
