import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {TransferState, makeStateKey} from '@angular/platform-browser';

import {environment} from '../../../environments/environment';

import {ApiInterface} from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiResource {
  constructor(private http: HttpClient, private state: TransferState) { }

  /**
   * GET wrapper calle
   */
  public get(options: any): Promise<any> {
    options.method = 'GET';
    return this.call(options);
  }

  /**
   * POST wrapper calle
   */
  public post(options: any): Promise<any> {
    options.method = 'POST';
    return this.call(options);
  }

  public patch(options: any): Promise<any> {
    options.method = 'PATCH';
    return this.call(options);
  }

  /**
   * PUT wrapper calle
   */
  public put(options: any): Promise<any> {
    options.method = 'PUT';
    return this.call(options);
  }

  /**
   * DELETE wrapper calle
   */
  public delete(options: any): Promise<any> {
    options.method = 'DELETE';
    return this.call(options);
  }

  /**
   * UPLOAD wrapper calle
   */
  public upload(options: any): Promise<any> {
    options.method = 'POST';
    options.fileUpload = true;
    return this.call(options);
  }

  /**
   * Get state
   */
  public getStateKey(key: any): Promise<any> {
    const dataLoaded = this.state.get(key, null as any);
    if (dataLoaded === null || dataLoaded === undefined) {
      makeStateKey(key);
    }
    return dataLoaded;
  }

  /**
   * Set state
   */
  public setStateData(key: any, data: any): void {
    this.state.set(key, data);
  }

  /**
   * Main function to make requests
   */
  protected call(options: any): Promise<any> {
    let url;
    let isBlog = false;

    if (options.loader) {
      // TODO find a way to abstract this event dispatcher
      if (typeof window === 'object') {
        window.dispatchEvent(new CustomEvent('loading-animation', {detail: {set: true}}));
      }
    }
    return new Promise((resolve, reject) => {
      options = options || {};
      // Check if servica starts by /
      if (options.service && !options.service.startsWith('/') && !options.service.startsWith('https')) {
        options.service = '/' + options.service;
      }
      let params = new HttpParams();
      const queryParams = {};
      if (options.hasOwnProperty('params')) {
        Object.assign(queryParams, options.params);
      }
      for (const p in queryParams) {
        if (queryParams.hasOwnProperty(p)) {
          params = params.set(p, queryParams[p]);
        }
      }
      // Compone use
      if (options.bapi === true) {
        url = environment.bapi.host + (options.version || environment.bapi.default_api_version) + options.service;
      } else if (!options.service.startsWith('https') && !options.noApi) {
        url = environment.capi.host + (options.version || environment.capi.default_api_version) + options.service;
      } else if (!options.noApi) {
        isBlog = true;
        url = options.service;
        params = params.set('wp', 'true');
      } else {
        url = options.service;
      }
      // Compose request
      const requestOptions = new HttpRequest(options.method.toLowerCase(), url, options.body ? options.body : null, {
        reportProgress: false,
        params,
        responseType: 'json',
        withCredentials: true
      });
      this.http.request<ApiInterface>(requestOptions).subscribe(
        (event: any) => {
          if (event.type === 4) {
            if (!isBlog && event?.body?.data !== undefined) {
              resolve(event.body.data);
            } else {
              resolve(event.body);
            }

            if (options.loader) {
              if (typeof window === 'object') {
                // TODO find a way to abstract this event dispatcher
                window.dispatchEvent(new CustomEvent('loading-animation', {detail: {set: false}}));
              }
            }
          }
        },
        err => {
          reject(err.error);
          if (options.loader) {
            if (typeof window === 'object') {
              // TODO find a way to abstract this event dispatcher
              window.dispatchEvent(new CustomEvent('loading-animation', {detail: {set: false}}));
            }
          }
        }
      );
    });
  }
}
