import {Component, OnInit, Injector, OnDestroy, ViewEncapsulation, HostBinding} from '@angular/core';
import {PopoverBaseComponent} from '../base/base.component';
import { RouterService, StorageService} from '../../services';
import {CountryService} from 'src/app/services/country/country.service';

@Component({
  selector: 'location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationLangComponent extends PopoverBaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.popover-is-open') isOpen;
  public topSpace = true;
  public country: string;
  public products: any;
  public languages = this.langSrv.getMenuLangs();
  public countries: any;
  public countriesByIso: any;
  public currentIso: any;
  public onClose: any;
  public locationOpen = false;
  public langOpen = false;
  public newLang: string;
  public newCountry: string;

  constructor(
    public injector: Injector,
    private countrySrv: CountryService,
    public routerSrv: RouterService,
    private storageSrv: StorageService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    if (!this.countries) {
      this.countries = await this.countrySrv.get();
    }
    if (!this.countriesByIso) {
      this.countriesByIso = await this.countrySrv.getCountriesByISO();
    }
    this.currentIso = this.countrySrv.getCountry();

    this.countries.sort((a, b) => {
      const x = a._m_name[this.langSrv.getCurrentLang()];
      const y = b._m_name[this.langSrv.getCurrentLang()];
      return x < y ? -1 : x > y ? 1 : 0;
    });

    this.start({
      active: true,
      style: 'background-color: rgba(0,0,0,0); z-index: 201',
      close: () => this.closeWithAnimation()
    });

    setTimeout(() => {
      this.isOpen = true;
    }, 0);
  }

  /**
   * Change lang
   */
  public changeLang(value: string): Promise<any> {
    if (!this.domSrv.isPlatformBrowser()) {
      return;
    }

    const scroll = window.scrollY || window.pageYOffset;
    this.routerSrv.navigate(this.routerSrv.removeUrlLang(), value.toLowerCase());

    this.storageSrv.set('lastLanguage', value);

    void this.textSrv.get(value.toLowerCase());

    setTimeout(() => {
      window.scroll({
        top: scroll,
        left: window.innerHeight / 2
      });
    }, 0);
  }

  /**
   * Change country
   */
  public changeCountry(value: any): void {
    this.countrySrv.setCountry(value);
    this.currentIso = value;
  }

  public accept(): void {
    if (this.newLang) {
      void this.changeLang(this.newLang);
    }
    if (this.newCountry) {
      this.changeCountry(this.newCountry);
    }
    this.closeWithAnimation(true);
  }

  ngOnDestroy(): void {
    this.isOpen = false;
  }

  /**
   * Close popover
   */
  public closeWithAnimation(closeMobileMenu: boolean = false): void {
    this.isOpen = false;

    setTimeout(() => {
      this.close();
      this.onClose(this.currentIso);
      if (closeMobileMenu) {
        this.utilsSrv.closeMobileMenu();
      }
    }, 300);
  }
}
