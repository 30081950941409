import {loadConfig} from '../../config/loadConfig';
loadConfig();

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private ready = false;
/*
  constructor(private remote: AngularFireRemoteConfig) { }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async init() {
    // Fecth and activate remote config
    await this.remote.fetchAndActivate();

    this.ready = await this.getBoolean('ready');

    return this.ready;
  }

  public getAll(): Promise<any> {
    return this.remote.getAll();
  }

  public getBoolean(key: string): Promise<boolean> {
    return this.remote.getBoolean(key);
  }

  public getValue(key: string): Promise<any> {
    return this.remote.getValue(key);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static factory(configService: ConfigService) {
    return async (): Promise<boolean> => await configService.init();
  } */
}
