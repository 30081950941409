import {
  BrowserModule,
  BrowserTransferStateModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { PopoverModule } from './popover/popover.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './components/header';
import { MobileMenuComponent } from './components/mobile-menu';
import { CookiesComponent } from './components/cookies';
import { LoaderComponent } from './components/loader';

import { InterceptorResource } from './resources';
import { CFDesignModule } from '@crowdfarming/cf-design';
import { DsLibraryModule } from '@crowdfarming/ds-library';
import { SharedModule } from './modules/shared/shared.module';
import { HammerConfig } from './config';

import { BaseComponent } from './popups/base/base.component';
import { InsertionDirective } from './popups/insertion.directive';

import { ErrorService } from './services/error/error.service';

// import {DeviceDetectorModule} from 'ngx-device-detector';
import { NgxLogglyModule } from 'ngx-loggly-logger';

import { GenericPopupModule } from './popups/generic-popup/generic-popup.module';
import { StoreButtonsModule } from './components/store-buttons/store-buttons.module';

import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeDe);

import { AlertModule } from './components/alert';

// Transloco
import { TranslocoRootModule } from './modules/transloco/transloco-root.module';

@NgModule({
  declarations: [
    // Components
    BaseComponent,
    AppComponent,
    HeaderComponent,
    MobileMenuComponent,
    CookiesComponent,
    LoaderComponent,
    // Popups
    InsertionDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CFDesignModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    FormsModule,
    StoreButtonsModule,
    HttpClientModule,
    // DeviceDetectorModule,
    DsLibraryModule,
    NgxLogglyModule.forRoot(),
    CommonModule,
    PopoverModule,
    SharedModule,
    // DeviceDetectorModule.forRoot(),
    // Popups
    GenericPopupModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideRemoteConfig(() => getRemoteConfig()),
    AlertModule,
    // Transloco
    TranslocoRootModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorResource, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
