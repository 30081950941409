import { Inject, Injectable, Injector } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';

import { Subject, Observable } from 'rxjs';
import { StorageService } from '../storage';
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LangService implements CanActivate {
  constructor(
    private router: Router,
    public injector: Injector,
    private storageSrv: StorageService,
    private translocoSrv: TranslocoService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setConfigLangs();
  }

  private setConfigLangs(): void {
    this.translocoSrv.setDefaultLang(environment.langs?.default);
    this.translocoSrv.setAvailableLangs(environment.langs?.available);
  }

  /**
   * Get languages available
   */
  private getLanguagesAvailable(): Array<string> {
    return this.translocoSrv.getAvailableLangs() as string[];
  }

  /**
   * Get lang from browser if not return default one
   */
  private getLangBrowser(): string {
    if (typeof navigator === 'object') {
      for (const lang of navigator.languages) {
        return lang.substring(0, 2).toLowerCase();
      }
    }

    return this.getDefaultLang();
  }

  /**
   * Return input language if available
   * Otherwise returns default
   */
  private availableLanguageOrDefault(lang: string): string {
    return this.getLanguagesAvailable().includes(lang)
      ? lang
      : this.getDefaultLang();
  }

  /**
   * Get default lang
   */
  public getDefaultLang(): string {
    return this.translocoSrv.getDefaultLang();
  }

  /**
   * Set language
   */
  public setCurrentLang(lang: string): void {
    lang = this.availableLanguageOrDefault(lang);

    this.translocoSrv.setActiveLang(lang);
    this.storageSrv.set('lang', lang);
    this.document.documentElement.lang = lang;
  }

  /**
   * Get current language
   */
  public getCurrentLang(): string {
    return (
      this.translocoSrv.getActiveLang() ||
      this.storageSrv.get('lang') ||
      this.getDefaultLang()
    );
  }

  /**
   * Get menu languages availables
   */
  public getMenuLangs(): any[] {
    return [
      { longName: 'English', shortName: 'EN' },
      { longName: 'Español', shortName: 'ES' },
      { longName: 'Deutsch', shortName: 'DE' },
      { longName: 'Français', shortName: 'FR' },
    ];
  }

  /**
   * Subscription to transloco on change
   */
  public onChange(): Observable<string> {
    return this.translocoSrv.langChanges$;
  }

  /**
   * Translate Key Asynchronous
   */
  public translateAsync(
    key: string,
    params?: Record<string, string | number>
  ): Promise<string> {
    const translation$ = this.translocoSrv.selectTranslate(key, params);
    return translation$.pipe(first()).toPromise();
  }

  /**
   * Guard function
   */
  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const lang = (route.params?.lang as string) ?? '';
    const defaultLang = this.availableLanguageOrDefault(
      this.storageSrv.get('lang') ?? this.getLangBrowser()
    );

    if (lang) {
      if (!this.getLanguagesAvailable().includes(lang)) {
        // If path does not include an available language ex:"/watermelon"
        void this.router.navigate([`/${defaultLang}`]);
      } else {
        // If path includes a language ex:"/en"
        this.setCurrentLang(lang);
      }
    } else {
      // Redirect to default lang
      void this.router.navigate([`/${defaultLang}`]);
    }

    return true;
  }
}
