<div id="loading" *ngIf="active" class="bgLoader">
  <div class="ringContainer">
    <div class="ring"></div>
  </div>
  <div class="iconContainer">
    <div class="iconBox iconBox1">
      <div class="icon"></div>
    </div>
    <div class="iconBox iconBox2">
      <div class="icon"></div>
    </div>
  </div>
</div>
