<div
  class="header-container"
  id="header-container"
  *ngIf="!routerSrv.getPath().includes('open-issue')"
  [attr.data-cy]="'header-container-wrapper'"
>
  <div id="header-notification-container"></div>

  <!-- [ngClass]="{'solid-background': domSrv.getIsDeviceSize() || !routerSrv.getIsHome(), 'black-bg':  routerSrv.getPath().includes('order/confirm-order')}"> -->
  <div class="header cf-shadow-s solid-background">
    <!-- [ngClass]="{'solid-background': domSrv.getIsDeviceSize('tablet') || !routerSrv.getIsHome()}"> -->
    <div class="page-margin">
      <!-- Desktop Bar -->
      <div
        class="row-no-margin menu"
        [ngClass]="
          isNavHidden ? 'justify-content-center' : 'justify-content-between'
        "
      >
        <!-- Logo -->
        <div class="header-logo flex-center">
          <a
            class="flex-center"
            (click)="routerSrv.navigate('')"
            id="header-home-link"
          >
            <i class="fa cf-icon-crowdfarming-bold" aria-hidden="true"></i>
            <i class="fa cf-icon-text-light" aria-hidden="true"></i>
          </a>
        </div>

        <ng-container *ngIf="!isNavHidden">
          <!-- Navigation navbar -->
          <div class="header-navbar">
            <span
              class="t-component-semibold-m"
              *ngFor="let opt of sideNavOpt"
              (click)="goToSection(opt.id)"
              >{{ opt.name }}</span
            >
          </div>

          <!-- Languages -->
          <div class="menu-right">
            <div
              class="pointer flex-center"
              *ngIf="canShare"
              (click)="sharePage()"
            >
              <i class="eva eva-share f22" aria-hidden="true"></i>
            </div>
            <div
              class="header-location-language flex-center ml-s"
              (click)="locationAndLanguage()"
              [ngClass]="{ 'header-location-language-open': locationLangOpen }"
            >
              <i class="eva eva-pin-outline f22" aria-hidden="true"></i>
              <div class="t-m-medium">
                {{ langSrv.getCurrentLang().toUpperCase() }}
              </div>
              <i
                class="eva eva-chevron-down-outline f20"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <li
            class="menu-icon"
            (click)="openMenu()"
            *ngIf="sideNavOpt && sideNavOpt.length"
          >
            <div class="flex-center mx-2">
              <a class="flex-center" id="navbar-menu-link">
                <span
                  class="eva eva-{{
                    utilsSrv.isMobileMenuOpen()
                      ? 'close-outline'
                      : 'menu-outline'
                  }} f24"
                  aria-hidden="true"
                ></span>
              </a>
            </div>
          </li>
        </ng-container>
      </div>

      <!-- Mobile Menu -->
    </div>
  </div>

  <div id="up-card-mobile-container"></div>
</div>
