<div #subscribe class="lead-f2b-popover align-items-center d-flex justify-content-center" [attr.data-cy]="'lead-f2b-popover'">
  <div class=" modal-container">
    <div class="popover-close pointer text-right pt-3 pr-4 t-h5-black t-black-T" (click)="closeWithAnimation(true)"
      [attr.data-cy]="'lead-f2b-popover-close-button'">
      <i class="fa fa-times" aria-hidden="true" [attr.data-cy]="'lead-f2b-popover-close-button-icon'"></i>
    </div>
    <div class="body d-flex flex-row flex-md-column p-4" [attr.data-cy]="'lead-f2b-popover-body'">
      <img class="m-auto"
        [src]="'https://common.crowdfarming.com/uploaded-images/1643101882716-2fd8c96f-c7bb-42b2-8037-b630eb748c61.png'"
        alt="">
      <h4 class="title t-h4-black t-a-center t-black-T d-block pb-4 pt-4" [attr.data-cy]="'customer-title'">
        {{textSrv.getText('notifications.thanks-for-joining.text-info')}}
      </h4>
      <p class="t-l-medium t-grey pb-5 px-3">{{textSrv.getText('notifications.what´s-next.text-info')}}</p>

      <cf-button (click)="closeWithAnimation(true)" id="footer-email-btn">
        {{textSrv.getText('notifications.close.button')}}
      </cf-button>
    </div>
  </div>
</div>