import {Injectable, Injector} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TransferStateService} from '../../services';


import {BaseService} from '../base';
import {TextsResource} from '../../resources';
import {LangService} from '../lang';

import {environment} from '../../../environments/environment';

import * as _dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

const dayjs = _dayjs;
dayjs.extend(utc);

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class TextService extends BaseService implements CanActivate {
  private texts: any = {};
  private loadedText = 'noLoaded';
  private SimpleMDE: any;

  // Mandatory implementation
  public init: any = () => null;

  constructor(
    private textsRsc: TextsResource,
    private langSrv: LangService,
    public injector: Injector,
    private stateSrv: TransferStateService
  ) {
    super(injector);
  }

  /**
   * Guard function
   */
  public async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<any> {
    // Check if texts are loaded
    if (this.checkTexts()) {
      return true;
    }

    // Load texts
    await this.get();

    return true;
  }

  /**
   * Check if texts are loaded
   */
  private checkTexts(): boolean {
    let result = false;

    // Check if variabel exists and type is function of object
    if (
      (this.loadedText === this.langSrv.getCurrentLang() && this.texts && typeof this.texts === 'function') ||
      typeof this.texts === 'object'
    ) {
      for (const key in this.texts) {
        if (key) {
          result = true;
          break;
        }
      }
    }

    return result;
  }

  /**
   * Fetch texts from server
   */
  public async get(forceLang = ''): Promise<any> {
    if (this.loadedText === forceLang) {
      return;
    }
    const lang = (forceLang !== '') ? forceLang : this.langSrv.getCurrentLang();
    const stateKey = `texts_${ lang }`;
    let texts = this.stateSrv.get(stateKey, null);
    if (texts === null) {
      texts = await this.textsRsc.get(lang);
      this.stateSrv.set(stateKey, texts);
    }
    this.loadedText = lang;
    // Clean and set all texts in service
    Object.keys(texts).forEach(key => {
      this.texts[key] = texts[key];
    });
    this.langSrv.setCurrentLang(lang);
  }

  /**
   * Get text in current language by id
   */
  public getText(id: string, replacements?: any): string {
    let translated: string;
    if (this.texts.hasOwnProperty(id)) {
      translated = this.texts[id];
    } else {
      translated = id;
    }
    if (replacements) {
      translated = this.replaceAll(translated, replacements);
    }
    return (translated || '').replace(/\n/g, '');
  }

  /**
   * Get text id
   */
   public getId(id: string): any {
    let textId: string;
    if (this.texts.hasOwnProperty(id)) {
      textId = this.texts[id].id;
    } else {
      textId = id;
    }
    return textId;
  }

  /**
   * Build dynamic urls from multilingual objects with replacements
   */
  public buildDynamicUrl(urlObj: any, replacements: any): string {
    return this.replaceAll(urlObj[this.langSrv.getCurrentLang()], replacements);
  }
  /**
   * Markdown
   */
  public markdown(text: string, replacements: any = null): string {
    if (!this.SimpleMDE) {
      this.loadSMDE();
    } else {
      return this.SimpleMDE.prototype.markdown(text);
    }
    if (replacements) {
      text = this.replaceAll(text, replacements);
    }

    return text;
  }

  /**
   * Loads simpleMDE
   */
  private loadSMDE() {
    const simpleSrc = environment.simpleMDE.url;

    if (typeof window === 'object') {
      const simpleScript = document.querySelector(`script[src='${simpleSrc}']`);

      if (!simpleScript) {
        const simple = document.createElement('script');

        simple.src = simpleSrc;
        simple.defer = true;

        simple.onload = () => {
          this.SimpleMDE = window.SimpleMDE;
        };

        document.body.appendChild(simple);
      }
    }
  }

  /**
   * escapeRegExp
   */
  private escapeRegExp(str: string): string {
    // eslint-disable-next-line no-useless-escape
    return (str = str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'));
  }

  /**
   * This is for farmer up
   */
  public replaceAll(str: string, replacements: any): any {
    if (str && replacements) {
      for (const replacement in replacements) {
        if (replacements[replacement] || replacements.hasOwnProperty(replacement)) {
          str = str.replace(new RegExp(this.escapeRegExp(replacement), 'g'), replacements[replacement]);
        }
      }
    }

    return str;
  }
}
