import { Injectable, Injector } from '@angular/core';
import { F2BFarmers } from '../../interfaces/f2b-data';
import { HomeResource } from '../../resources/home';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService extends BaseService {
  constructor(public injector: Injector, private homeRsc: HomeResource) {
    super(injector);
  }

  // Mandatory implementation
  public init = (): void => null;

  public async getCounters(): Promise<any> {
    let counters = await this.homeRsc.getCounters();
    counters = this.modelize(counters);
    return counters;
  }

  public async getFarmersData(): Promise<F2BFarmers[]> {
    const data = await this.homeRsc.getFarmers();

    return data.farmers;
  }
}
