import { Injectable, Injector } from '@angular/core';

import { BaseService } from '../base';
import { LeadsResource } from '../../resources/leads/leads.resource';

@Injectable({
  providedIn: 'root',
})
export class LeadsService extends BaseService {
  // Mandatory implementation
  public init: any = () => null;

  constructor(private leadRsc: LeadsResource, public injector: Injector) {
    super(injector);
  }

  public get(leadId: string): Promise<any> {
    return this.leadRsc.get(leadId);
  }

  public create(body: any): Promise<any> {
    return this.leadRsc.create(body);
  }

  public async getContactOpts(): Promise<any> {
    return await this.leadRsc.getContactOpts();
  }

  public getUploadInfo(leadId: string): Promise<any> {
    return this.leadRsc.getUploadInfo(leadId);
  }

  public updateAddressesFile(
    leadId: string,
    addressesFile: string
  ): Promise<any> {
    return this.leadRsc.updateAddressesFile(leadId, addressesFile);
  }
}
