import { Component, Injector, HostBinding } from '@angular/core';
import { PopoverBaseComponent } from '../base/base.component';

@Component({
  selector: 'lead-f2b-popover',
  templateUrl: './lead-f2b-popover.html',
  styleUrls: ['./lead-f2b-popover.scss'],
})
export class Leadf2bPopoverComponent extends PopoverBaseComponent {
  @HostBinding('class.popover-is-open') isOpen;
  public onClose: any;

  constructor(public injector: Injector) {
    super(injector);
  }

  public closeWithAnimation(blockPopover: boolean = false): void {
    this.isOpen = false;

    setTimeout(() => {
      this.close();
      this.onClose(blockPopover);
    }, 300);
  }
}
