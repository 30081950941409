import {Injectable, Injector, ErrorHandler, Optional, Inject} from '@angular/core';
import {BaseService} from '../base';
import {LoggerService} from '../logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorService extends BaseService implements ErrorHandler {
  constructor(
    @Optional() @Inject('ERROR_WRAPPER') private errorWrapper: any,
    public injector: Injector,
    private logger: LoggerService
  ) {
    super(injector);
  }

  showError(error: any): void {
    this.logger.error(error.toString());
    if (this.errorWrapper) {
      this.errorWrapper.error = error;
    }
  }

  public init: any = () => null;

  handleError(error: any): void {
    if (error.promise !== undefined && error.promise.catch !== undefined) {
      error.promise.catch(() => {
        this.showError(error);
      });
    } else {
      this.showError(error);
    }
  }
}
