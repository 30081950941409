<global-styles></global-styles>
<div
  id="app-block"
  #appBlock
  [ngClass]="{
    'header-margin': domSrv.isHeaderActive(),
    'grey-page': routerSrv.getPath().includes('open-issue')
  }"
>
  <cookies-component *ngIf="cookiesSrv.isVisible()"></cookies-component>
  <alert></alert>
  <header-component
    *ngIf="domSrv.isHeaderActive()"
    [ngStyle]="{
      top: cookiesSrv.isVisible()
        ? domSrv.getElementHeight('#cookies-component-wraper') + 'px'
        : '0px'
    }"
    [sideNavOpt]="sideNavOpt"
    (clickSideNav)="goToSection($event)"
    (clickMobileMenu)="openMobileMenu()"
  >
  </header-component>
  <mobile-menu
    [sideNavOpt]="sideNavOpt"
    [cookies]="cookiesSrv.isVisible()"
  ></mobile-menu>
  <router-outlet></router-outlet>
</div>
<loader-component></loader-component>
