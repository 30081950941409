import {environment} from '../../environments/environment';

export const loadConfig = (): Environment => {
  const platform = envType();
  let env = {};
  if (platform === 'unknown') {
    throw new Error('No podemos cargar configuraciones');
  }
  if (platform === 'server') {
    env = loadSsrJson();
  } else {
    env = loadWebJson();
  }
  Object.assign(environment, env);
  return environment;
};

const envType = () => {
  let type = '';
  try {
    if (process !== undefined) {
      type = 'server';
    }
  } catch (e) {
    try {
      if (document !== undefined) {
        type = 'browser';
      }
    } catch (f) {
      type = 'unknown';
    }
  }
  return type;
};

const loadSsrJson = () => {
  const fs = require('fs');
  const path = __dirname + environment.serverConfig;
  const data = fs.readFileSync(path);
  return JSON.parse(data.toString('utf-8'));
};

const loadWebJson = () => {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', environment.browserConfig, false);
  if (xmlhttp.overrideMimeType) {
    xmlhttp.overrideMimeType('application/json');
  }
  xmlhttp.send();
  if (xmlhttp.status === 200 && xmlhttp.readyState === 4) {
    return JSON.parse(xmlhttp.responseText);
  } else {
    throw new Error('Error loading config file');
  }
};

