import {Injectable} from '@angular/core';
import {HammerGestureConfig} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}
