import {Injectable, Injector} from '@angular/core';

import {BaseService} from '../base';
import {StorageService} from '../storage';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {environment} from '../../../environments/environment';
import {TrackingService} from '../tracking/tracking.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService extends BaseService {
  private lastUrl: string;

  constructor(private storageSrv: StorageService, public injector: Injector, private trackingSrv: TrackingService) {
    super(injector);
  }

  /**
   * Init function of cookies service
   */
  public init(): void {
    // Check if cookis exists on storage
    if (!this.storageSrv.get('cookies')) {
      this.storageSrv.set('cookies', {marketingAllowed: true}, 31557600);
    }
  }

  /**
   * Set cookies data
   */
  public set(cookies: any): void {
    this.storageSrv.set('cookies', cookies, 31557600);
  }

  /**
   * Get method
   */
  public get(): any {
    return this.storageSrv.get('cookies');
  }

  /**
   * Accept cookies
   */
  public accept(): void {
    this.storageSrv.set('cookiesPopup', true, 31557600); // 365.25 days
  }

  /**
   * return if cookies alerter shoudl be visible
   */
  public isVisible(): boolean {
    return typeof window !== 'undefined' && !this.storageSrv.get('cookiesPopup');
  }

  /**
   * Hsndle for cookie manage
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Cookies are acceptes if you continue with the navigation
    if (this.lastUrl && this.lastUrl !== state.url) {
      this.accept();
    }

    this.lastUrl = state.url;

    // Check hotjar
    if (
      environment.hotjar &&
      environment.hotjar.active &&
      environment.hotjar.routes &&
      environment.hotjar.routes.length > 0 &&
      !this.trackingSrv.getIsEnabled('HJ')
    ) {
      for (const routeUrl of environment.hotjar.routes) {
        if (environment.hotjar.exactRoute) {
          if (state.url.endsWith(routeUrl)) {
            this.trackingSrv.loadHotjar();
            break;
          }
        } else {
          if (state.url.includes(routeUrl)) {
            this.trackingSrv.loadHotjar();
            break;
          }
        }
      }
    }

    return true;
  }

  /**
   * Hsndle for cookie manage
   */
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
