<div class="container-fluid is-mobile">
  <div
    class="wrapper-mobile-menu"
    id="wrapper-mobile-menu"
    [ngStyle]="{
      top: !cookies
        ? '69px'
        : domSrv.getElementHeight('#cookies-component-wraper') + 69 + 'px'
    }"
  >
    <div class="mobileMenuContainer mh-100">
      <!-- Links -->
      <div
        class="col-12 pointer mobile-link-container mobile-link-menu"
        (click)="navToSection(opt.id)"
        *ngFor="let opt of sideNavOpt"
      >
        <div class="d-flex align-items-center t-component-semibold-m">
          {{ opt.name }}
        </div>
      </div>

      <!--  <div class="col-11 mobile-line mt-m"></div> -->

      <!-- Languages -->
      <!--    <div class="col-12 pointer mobile-link-container mobile-link-menu text-m-medium d-flex align-items-center mt-m"
        (click)="showCountriesLangs()">
        <i class="eva eva-pin-outline f22" aria-hidden="true"></i>

        <div>
          {{textSrv.getText('lang_' + langSrv.getCurrentLang())}}
        </div>
        <i class="eva eva-chevron-down-outline f22" aria-hidden="true"></i>
      </div> -->
    </div>
  </div>

  <div
    class="background-popup mobile-menu-background"
    (click)="closeMobileMenu()"
  ></div>
</div>
